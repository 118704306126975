import React, { useEffect } from 'react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Alert = ({
    variant,
    message,
    size,
    icon,
    iconStyle,
    noSpace,
    isSubmitting,
}) => {
    useEffect(() => {
        if (message) {
            const options = {
                type: variant,
                autoClose: 3000,
            }
            // if (!toast.isActive('alert')) {

            toast(message, {
                toastId: 'alert',
                ...options,
            })
            // } else {
            //     toast.update('alert', {
            //         render: message,
            //         ...options,
            //     })
            // }
        }
    }, [variant, message, isSubmitting])

    return <ToastContainer position="bottom-right" />
}

export default Alert
